<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Ruangan'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/appointment-splash.svg"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Lantai</strong></td>
                    <td>{{ data.floor }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Gedung</strong></td>
                    <td>{{ data.building_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Deskripsi</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                </table>

                <button
                  @click="$router.push({path: '/masters/rooms/edit/' + data.id})"
                  v-if="btn"
                  class="btn mx-1 btn-success"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {
        name: "",
        description: ""
      },
      // Other
      imgLoaded: false,
      // user access
      btn: false,
    }
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('rooms/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/masters/rooms/list')
      }
    },

    async get() {
      this.data = await module.get('rooms/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/masters/rooms/list')
      }
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "1802"){
          this.btn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ruangan", route: "" },
      { title: "Daftar Ruangan", route: "/masters/rooms/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.setActiveButton()
  },
}

</script>